/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';
import { useIntl } from 'gatsby-plugin-intl';
import { mainMenuItems } from '../utils/utils';

export const StyledMenu = styled.nav`
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: #fff;
  transform: ${({ open }) => (open ? 'translateX(0)' : 'translateX(-100%)')};
  height: 100vh;
  text-align: left;
  position: absolute;
  top: 0;
  left: 0;
  transition: all 0.3s ease-in-out;
  z-index: 100;
  width: 100%;
  @media (min-width: 1100px) {
    background: transparent;
    position: relative;
    transform: none;
    height: initial;
    width: auto;
  }
`;

const NavList = styled.ul`
  list-style: none;
  display: block;
  padding: 0;
  margin: 0 auto;
  max-width: 300px;
  text-align: center;
  @media (min-width: 1100px) {
    display: flex;
    flex-direction: row;
    max-width: unset;
  }
`;

const NavItem = styled.li`
  background: var(--black);
  margin: 0 18px;
  transform: rotate(-1deg);
  transition: all 0.15s;
  margin-bottom: 16px;
  font-size: 22px;
  @media (min-width: 1100px) {
    margin-bottom: 0;
    font-size: 18px;
  }
  &:hover {
    transform: rotate(1deg);
  }
  a {
    color: var(--white);
    padding: 8px 16px;
    display: block;
    text-decoration: none;
    text-transform: uppercase;
    font-family: 'SpecialElite-Regular', sans-serif;
  }
`;

export default function Menu({ open, setOpen, ...props }) {
  const isHidden = !!open;
  const tabIndex = isHidden ? 0 : -1;
  const intl = useIntl();
  const getMenuItems = () =>
    mainMenuItems.map((item) => (
      <NavItem key={item}>
        <Link
          to={`/${intl.locale}/${item}`}
          tabIndex={tabIndex}
          onClick={() => setOpen(false)}
        >
          {intl.formatMessage({ id: `${item}` })}
        </Link>
      </NavItem>
    ));

  return (
    <StyledMenu open={open} aria-hidden={!isHidden} {...props}>
      <NavList>{getMenuItems()}</NavList>
    </StyledMenu>
  );
}
