import { createGlobalStyle } from 'styled-components';
import bg2 from '../assets/images/bg_small.jpg';

const GlobalStyles = createGlobalStyle`
  :root {
    --black: #2E2E2E;
    --blue: #272D33;
    --white: #f2f2f2;
    --grey: #efefef;
    --dark-grey: #3636;
    --pink: #e11487;
    --mint: #11d5c2;
    --rmint: 17, 213, 194;
    /* --glitch-opacity: 1;
    --glitch-color-1: 255, 0, 255;
    --glitch-color-2: 0, 255, 255; */
    --purple: 255, 0, 255, 1;
    --spacing: 8px;
    --page-width: 900px;
    --mobile: 1100px;
  }

  html {
    background-size: 450px;
    background-attachment: fixed;
    font-size: 10px;
    box-sizing: border-box;
  }

  *, *:before, *:after {
    box-sizing: inherit;
  }

  :focus {
    outline-color: var(--pink);
    outline-width: 2px;
    outline-style: dashed;
  }

  body {
    background: url(${bg2});
    font-size: 2rem;
    overflow-x: hidden;
    overflow-y: ${(props) => (props.open ? 'hidden' : 'scroll')};
  }

  fieldset {
    border-color: rgba(0,0,0,0.1);
    border-width: 1px;
  }

  /* button {
    background: var(--red);
    color: white;
    border: 0;
    padding: 0.6rem 1rem;
    border-radius: 2px;
    cursor: pointer;
    --cast: 2px;
    transition: all 0.2s;
    &:hover {
      --cast: 4px;
    }
  } */

  .gatsby-image-wrapper img[src*=base64\\,] {
    image-rendering: -moz-crisp-edges;
    image-rendering: pixelated;
  }

  /* Scrollbar Styles */
  body::-webkit-scrollbar {
    width: 12px;
  }
  html {
    scrollbar-width: thin;
    scrollbar-color: var(--pink) var(--white);
  }
  body::-webkit-scrollbar-track {
    background: var(--white);
  }
  body::-webkit-scrollbar-thumb {
    background-color: var(--pink) ;
    border-radius: 6px;
    border: 3px solid var(--dark-grey);
  }

  img {
    max-width: 100%;
  }
`;

export default GlobalStyles;
