import { createGlobalStyle } from 'styled-components';
import copyFont from '../assets/fonts/RobotoMono-Regular.ttf';
import headingFont from '../assets/fonts/PermanentMarker-Regular.ttf';
import bodyFont from '../assets/fonts/SpecialElite-Regular.ttf';

const Typography = createGlobalStyle`
  @font-face {
    font-family: SpecialElite-Regular;
    src: url(${bodyFont});
    font-display: swap;
  }
  @font-face {
    font-family: Permanent-Marker;
    src: url(${headingFont});
    font-display: swap;
  }
  @font-face {
    font-family: RobotoMono-Regular;
    src: url(${copyFont});
    font-display: swap;
  }
  html {
    font-family: SpecialElite-Regular, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    color: var(--black);
  }

  p, li {
    letter-spacing: 0.5px;
    font-size: 18px;
  }

  h1,h2 {
    font-family: 'Permanent-Marker', sans-serif;
    font-weight: normal;
    margin: 0;
  }

  h3 { font-size: 2.5rem ;}

  h3,h4,h5,h6 {
    font-family: 'SpecialElite-Regular', sans-serif;
    font-weight: normal;
    margin: 0;
  }

  .body-copy {
    font-family: 'RobotoMono-Regular', sans-serif;
    letter-spacing: 0;
    line-height: 1.24;
  }

  a {
    color: var(--black);
    text-decoration: none;
  }

  .center {
    text-align: center;
  }
`;

export default Typography;
