import React from 'react';
import styled from 'styled-components';

const SkiplinkStyle = styled.a`
  clip: rect(1px, 1px, 1px, 1px);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
  &:focus {
    clip: auto;
    height: auto;
    overflow: auto;
    position: absolute;
    width: auto;
    background: var(--black);
    color: var(--white);
    padding: 8px;
  }
`;

export default function Skiplink() {
  return (
    <SkiplinkStyle href="#main" className="visible-hidden">
      Jump directly to main content
    </SkiplinkStyle>
  );
}
