import React, { useState } from 'react';

const PageContext = React.createContext();

export function PageProvider({ children }) {
  const [open, setOpen] = useState(false);
  return (
    <PageContext.Provider value={[open, setOpen]}>
      {children}
    </PageContext.Provider>
  );
}

export default PageContext;
