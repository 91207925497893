import React, { useContext } from 'react';
import styled from 'styled-components';
import { IntlProvider } from 'gatsby-plugin-intl';
import Header from './Header';
import GlobalStyles from '../styles/GlobalStyles';
import 'normalize.css';
import Typography from '../styles/Typography';
import Footer from './Footer';
import Skiplink from './Skiplink';
import PageContext from './PageContext';

const WrapperStyle = styled.div`
  background: rgba(48, 52, 53, 0.95);
  min-height: calc(100vh - 145px);
  padding: calc(var(--spacing) * 4) calc(var(--spacing) * 2);
  display: flex;
  justify-content: center;
`;

export default function Layout({ pageContext, children }) {
  const { intl } = pageContext;
  const [open] = useContext(PageContext);
  return (
    <div style={{ overflow: 'hidden' }}>
      <GlobalStyles open={open} />
      <Typography />
      <IntlProvider
        locale={intl.language}
        defaultLocale={intl.defaultLocale}
        messages={intl.messages}
      >
        <Skiplink />
        <Header />
        <WrapperStyle id="main">{children}</WrapperStyle>
        <Footer />
      </IntlProvider>
    </div>
  );
}
