import { Link } from 'gatsby';
import React, { useContext } from 'react';
import styled from 'styled-components';
import { useIntl, changeLocale } from 'gatsby-plugin-intl';
import FocusLock from 'react-focus-lock';
import PageContext from './PageContext';
import Burger from './Burger';
import Menu from './Menu';
import { CONSTANTS } from '../utils/constants';

const HeaderStyles = styled.header`
  background: var(--mint);
  background: rgba(var(--rmint), 0.97);
  border-bottom: solid 6px var(--black);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px 32px;
  width: 100%;
  a {
    text-decoration: none;
  }

  h1 {
    color: var(--black);
    font-size: 48px;
    font-weight: 400;
    text-shadow: 0 0 0 rgba(255, 0, 255, 0.25), 0 0 0 rgba(0, 255, 255, 0.25);
    transform: rotate(-1.5deg);
    @media (max-width: 769px) {
      font-size: 32px;
    }
    /* &:hover {
      -webkit-animation: glitch-small 0.75s cubic-bezier(0.25, 0.46, 0.45, 0.94)
        both infinite;
      animation: glitch-small 0.75s cubic-bezier(0.25, 0.46, 0.45, 0.94) both
        infinite;
    } */
  }

  /* @keyframes glitch-small {
    0% {
      text-shadow: 1px 1px 0px
          rgba(var(--glitch-color-1), var(--glitch-opacity)),
        -1px -1px 0 rgba(var(--glitch-color-2), var(--glitch-opacity));
    }

    33% {
      text-shadow: 2px 3px 0px
          rgba(var(--glitch-color-1), var(--glitch-opacity)),
        -3px -2px 0 rgba(var(--glitch-color-2), var(--glitch-opacity));
    }

    66% {
      text-shadow: -1px -1px 0px rgba(var(--glitch-color-1), var(--glitch-opacity)),
        1px 1px 0 rgba(var(--glitch-color-2), var(--glitch-opacity));
    }

    to {
      text-shadow: 2px 1px 0px
          rgba(var(--glitch-color-1), var(--glitch-opacity)),
        -1px -2px 0 rgba(var(--glitch-color-2), var(--glitch-opacity));
    }
  } */
`;

const Languages = styled.div`
  background: var(--black);
  text-align: right;
`;

export default function Header() {
  const intl = useIntl();
  const [open, setOpen] = useContext(PageContext);
  const menuId = 'main-menu';
  const devMode = process.env.GATSBY_DEV_MODE;
  const navigation =
    devMode === 'false' ? (
      <FocusLock disabled={!open}>
        <Burger open={open} setOpen={setOpen} aria-controls={menuId} />
        <Menu open={open} setOpen={setOpen} id={menuId} />
      </FocusLock>
    ) : null;
  return (
    <>
      <Languages>
        {CONSTANTS.languages.map((lang) => (
          <button
            type="button"
            key={lang}
            onClick={() => changeLocale(`${lang}`)}
          >
            {lang}
          </button>
        ))}
      </Languages>
      <HeaderStyles>
        <Link to={`/${intl.locale}/`}>
          <h1>Live in a Dive</h1>
        </Link>
        {navigation}
      </HeaderStyles>
    </>
  );
}
