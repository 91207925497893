import React from 'react';
import styled from 'styled-components';

const FooterStyles = styled.div`
  background: var(--black);
  color: var(--white);
  padding: 16px 32px;
  display: flex;
  align-items: center;
  p {
    margin: 0;
  }
`;

export default function Footer() {
  return (
    <footer>
      <FooterStyles>
        <p>
          <span>&copy;</span> Live in a Dive {new Date().getFullYear()}
        </p>
      </FooterStyles>
    </footer>
  );
}
